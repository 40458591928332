import React from 'react'

const About = () => {
    return (

        <>

            <section id='about'>
                <div className="container-fluid d-none d-sm-block">
                    <div className="container-lg p-lg-5 laila-semibold">
                        <div className="row d-flex align-item-center p-lg-4">
                            <div className='text-start mb-5' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold AboutCompany' style={{ fontSize: "15px", lineHeight: "1.2em", color: "#65cef5" }} >ABOUT COMPANY</h6>
                                </div>
                                <h4 className='laila-bold AboutCompanyBottom' style={{ fontSize: "48px", lineHeight: "1.2em", color: "#151515" }} > Spring water <br /> to homes & businesses </h4>
                            </div>

                            <div className="col-lg-12">
                                <div className=''>
                                    <h2 className=' d-flex align-item-center mb-0 p-lg-4 laila-bold StoryAboutCompony' style={{ backgroundColor: "#65CDF4", borderRadius: "7px 7px 0px 0px" }}> <span className='' style={{ color: "#fff", fontSize: "36px" }} ></span> <span className='ms-3 ' style={{ color: "#fff", fontSize: "16px" }} >COMPANY <br /> <b className=''> PROFILE </b></span> </h2>
                                    <div className='MainPositionTop'>
                                        <img className='' src={require("../images/About1.png")} alt='works' title='works' />
                                        <div className='MainPositionBottom'  >
                                            <p className='' style={{ fontSize: "14px", lineHeight: "30px", fontWeight: "400", marginBottom: "8px", color: "#585858" }}>
                                                Our company was founded in 2015, with a simple goal
                                                in mind: to bring clean and refreshing water to thirsty
                                                people everywhere. Since then, we've grown and
                                                expanded, building a reputation for excellence in the
                                                water industry. <strong> We also supply water bottles of ARIA, SUNRICH, and BAILEY </strong> With a focus on quality, reliability, and
                                                sustainability, we strive to exceed expectations and
                                                provide comprehensive water distribution services
                                                that cater to residential and commercial needs.
                                                We pride ourselves on our standard services.
                                                nor again there anyone who loves pursues desires to obtain pain of itself because it is because occasionally.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-sm-none d-block">
                    <div className="container-lg p-lg-5 mt-5 pt-5 laila-semibold">
                        <div className="row d-flex align-item-center p-lg-4">
                            <div className='text-center mb-5' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "15px", lineHeight: "1.2em", color: "#65cef5" }} >ABOUT COMPANY</h6>
                                </div>
                                <h4 className='  laila-bold' style={{ fontSize: "38px", lineHeight: "1.2em", color: "#151515" }} > Spring water  to homes & businesses </h4>
                            </div>

                            <div className="col-lg-12">
                                <div className=''>
                                    <h2 className=' d-flex align-item-center mb-0 p-lg-4 laila-bold StoryAboutCompony' > <span className='' style={{ color: "#fff", fontSize: "36px" }} ></span> <span className='ms-3 ' style={{ color: "#fff", fontSize: "16px" }} >STORY OUR <br /> <b className=''> COMPONY </b></span> </h2>
                                    <div className='MainPositionTop'>
                                        <img className='' src={require("../images/About1.png")} alt='works' title='works' />
                                        <div className='MainPositionBottom'  >
                                            <p className='' style={{ fontSize: "14.3px", lineHeight: "30px", fontWeight: "400", marginBottom: "8px", color: "#585858" }}>
                                                Our company was founded in 2015, with a simple goal
                                                in mind: to bring clean and refreshing water to thirsty
                                                people everywhere. Since then, we've grown and
                                                expanded, building a reputation for excellence in the
                                                water industry. <strong> We also supply water bottles of ARIA, SUNRICH, and BAILEY </strong> With a focus on quality, reliability, and
                                                sustainability, we strive to exceed expectations and
                                                provide comprehensive water distribution services
                                                that cater to residential and commercial needs.
                                                We pride ourselves on our standard services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid mt-5 d-none d-sm-block">
                    <div className="row d-flex justify-content-lg-around laila-semibold">
                        <div className="col-lg-4">
                            <img className='ABOUT2IMG' src={require("../images/About2.png")} alt='works' title='About2' />
                        </div>

                        <div className="col-lg-7">
                            <div className="row g-4">
                                <div className='text-start mb-5 mt-5' >
                                    <div className='' style={{ paddingBottom: "16px" }} >
                                        <h6 className='laila-bold' style={{ fontSize: "15px", lineHeight: "1.2em", color: "#65cef5" }} >WHY CHOOSE US</h6>
                                    </div>
                                    <h4 className='laila-bold' style={{ fontSize: "38px", lineHeight: "1.2em", color: "#151515" }} > You’ll love fresh <br /> taste of our pure water </h4>
                                </div>

                                <div className="col-lg-5 col-md-5">
                                    <div className='d-flex  '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Easy Booking</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-1 col-md-1"></div>

                                <div className="col-lg-5 col-md-5">
                                    <div className='d-flex  '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Same Day Delivery</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-1 col-md-1"></div>

                                <div className="col-lg-5 col-md-5">
                                    <div className='d-flex  '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Affordable</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-1 col-md-1"></div>

                                <div className="col-lg-5 col-md-5">
                                    <div className='d-flex  '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Quality Assurance</h2>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-lg-1 col-md-1"></div> */}

                                {/* <div className="col-lg-5 col-md-5">
                                    <div className='d-flex '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Rust Free  Epicoated Tanker</h2>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-lg-1 col-md-1"></div>

                                <div className="col-lg-5 col-md-5">
                                    <div className='d-flex  '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Safe, Reliable, & Refreshing</h2>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-lg-1 col-md-1"></div> */}

                                {/* <div className="col-lg-5 col-md-5">
                                    <div className='d-flex  '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Quality Assurance</h2>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-lg-1 col-md-1"></div>

                                <div className="col-lg-5 col-md-5">
                                    <div className='d-flex  '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > Contract</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-1 col-md-1"></div>

                                <div className="col-lg-5 col-md-5">
                                    <div className='d-flex  '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Hygenic Bottle</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-1 col-md-1"></div>

                                <div className="col-lg-5 col-md-5">
                                    <div className='d-flex  '>
                                        <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                        <div className='ms-5'>
                                            <h2 className='' style={{ fontSize: "16px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Certified</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-5 pt-5 pb-4 d-sm-none d-block">
                    <div className='container'>
                        <div className="row g-4 d-flex justify-content-around laila-semibold">
                            <div className="col-lg-4">
                                <img className='ABOUT2IMG' src={require("../images/About2.png")} alt='works' title='About2' />
                            </div>

                            <div className="col-lg-7">
                                <div className="row g-4">
                                    <div className='text-start mb-4 mt-5' >
                                        <div className='' style={{ paddingBottom: "16px" }} >
                                            <h6 className='laila-bold' style={{ fontSize: "15px", lineHeight: "1.2em", color: "#65cef5" }} >WHY CHOOSE US</h6>
                                        </div>
                                        <h4 className='laila-bold' style={{ fontSize: "38px", lineHeight: "1.2em", color: "#151515" }} > You’ll love fresh <br /> taste of our pure water </h4>
                                    </div>

                                    <div className="col-lg-5 col-md-5">
                                        <div className='d-flex  '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "15px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Easy Booking</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-5">
                                        <div className='d-flex  '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "15px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Same Day Delivery</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-5">
                                        <div className='d-flex  '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "15px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Affordable</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-5">
                                        <div className='d-flex  '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "15px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Quality Assurance</h2>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-5 col-md-5">
                                        <div className='d-flex '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "14px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Rust Free  Epicoated Tanker</h2>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-5 col-md-5">
                                        <div className='d-flex  '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "15px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Safe, Reliable, & Refreshing</h2>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-5 col-md-5">
                                        <div className='d-flex  '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "15px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Quality Assurance</h2>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-5 col-md-5">
                                        <div className='d-flex  '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "15px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > Contract</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-5">
                                        <div className='d-flex  '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "15px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Hygenic Bottle</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-5">
                                        <div className='d-flex  '>
                                            <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                            <div className='ms-5'>
                                                <h2 className='' style={{ fontSize: "15px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Certified</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block">
                    <div className="container-lg mb-5 pb-5  laila-semibold">
                        <div className="row g-4 d-flex justify-content-center">
                            <div className="col-10">
                                <div className=''  >
                                    <div className='OurMissionVission'>
                                        <h2 className='mb-2 pb-2 text-center' style={{ lineHeight: "40px", color: "#111", fontSize: "40px", fontWeight: "800" }} > OUR MISSION </h2>
                                        <p className='text-center' style={{ lineHeight: "30px", color: "#111", fontSize: "18px" }} >
                                            Our mission is to ensure that everyone
                                            has access to safe and reliable drinking
                                            water. We are committed to upholding
                                            our values of quality, integrity, and
                                            customer satisfaction in everything we
                                            do
                                        </p>
                                    </div>
                                </div>

                                {/* <img className='w-100' src={require("../images/mission2.png")} alt='works' title='About2' /> */}

                            </div>


                            {/* <div className="col-lg-5 col-md-6">
                                <div className='' >
                                    <div className='OurMissionVission'>
                                        <h2 className='mb-4 pb-2' style={{ lineHeight: "45px", color: "#111", fontSize: "40px", fontWeight: "800" }} > OUR VISSION </h2>
                                        <p className='' style={{ lineHeight: "30px", color: "#111", fontSize: "14px" }} >
                                            Work towards providing affordable water services
                                            Ensure the responsible and sustainable use of water resources to meet current needs
                                            Commit to delivering high-quality and safe drinking water that meets or exceeds regulatory standards
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-sm-none d-block">
                    <div className="container-lg mb-5 mt-3 laila-semibold">
                        <div className="row g-4 d-flex justify-content-around">
                            <div className="">
                                <div className=''  >
                                    <div className=' text-center'>
                                        <h2 className='' style={{ lineHeight: "49px", color: "#111", fontSize: "43px", fontWeight: "800" }} > OUR MISSION </h2>
                                        <p className='mt-3' style={{ lineHeight: "30px", color: "#111", fontSize: "18.7px" }} >
                                            Our mission is to ensure that everyone
                                            has access to safe and reliable drinking
                                            water. We are committed to upholding
                                            our values of quality, integrity, and
                                            customer satisfaction in everything we
                                            do
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-6 col-md-5">
                                <div className='' style={{ backgroundColor: "#004DA1", color: "#151515" }}>
                                    <div className='p-4'>
                                        <h2 className='mb-3 pb-2' style={{ lineHeight: "45px", color: "#fff", fontSize: "40px", fontWeight: "800" }} > OUR VISSION </h2>
                                        <p className='' style={{ lineHeight: "30px", color: "#fff", fontSize: "19px", fontWeight: "400" }} >
                                            Work towards providing affordable water services
                                            Ensure the responsible and sustainable use of water resources to meet current needs
                                            Commit to delivering high-quality and safe drinking water that meets or exceeds regulatory standards
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default About