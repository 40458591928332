import React from 'react'

const Product = () => {
    return (
        <>

            <section id='product'>
                <div className='container-fluid d-none d-sm-block' style={{ backgroundColor: "#ECF2F6" }} >
                    <div className="  laila-light pb-4" >
                        <div className='text-center mb-5 pt-5' >
                            <div className='' style={{ paddingBottom: "16px" }} >
                                <h6 className='laila-bold' style={{ fontSize: "14px", lineHeight: "1.2em", color: "#65cef5" }} >OUR PRODUCTS</h6>
                            </div>
                            <h4 className='  laila-bold' style={{ fontSize: "48px", lineHeight: "1.2em", color: "#151515" }} > Delivered fresh to your door <br /> by our team </h4>
                        </div>

                        <div className="row  row-cols-lg-5 row-cols-md-3 row-cols-sm-2 g-4 d-flex justify-content-start " >
                            <div className="">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='' style={{ padding: "30px" }} >
                                        <img className='w-100 border' style={{ height: "15.5rem" }} src={require("../images/200ml.png")} alt='200mlwaterbottle' title='200mlwaterbottle' />
                                    </div>
                                    <div className='text-center mb-lg-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Sunrich </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 200 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='' style={{ padding: "30px" }}>
                                        <img className='w-100 border' style={{ height: "15.5rem" }} src={require("../images/500ml.png")} alt='500mlwaterbottle' title='500mlwaterbottle' />
                                    </div>
                                    <div className='text-center mb-lg-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Sunrich </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 500 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='' style={{ padding: "30px" }}>
                                        <img className='w-100 border' style={{ height: "15.5rem" }} src={require("../images/1ltr.png")} alt='1ltrwaterbottle' title='1ltrwaterbottle' />
                                    </div>
                                    <div className='text-center mb-lg-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Bailley </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 1Ltr Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='' style={{ padding: "30px" }}>
                                        <img className='w-100 border' style={{ height: "15.5rem" }} src={require("../images/20ltr.png")} alt='20ltrwaterbottle' title='20ltrwaterbottle' />
                                    </div>
                                    <div className='text-center mb-lg-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Aria </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 20Ltr Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='' style={{ padding: "30px" }}>
                                        <img className='w-100 border' style={{ height: "15.5rem" }} src={require("../images/200mlUpdateNew.png")} alt='200mlA' title='20ltrwaterbottle' />
                                    </div>

                                    <div className='text-center mb-lg-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Aria </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 200 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid d-sm-none d-block' style={{ backgroundColor: "#ECF2F6" }} >
                    <div className="container  laila-light pb-5" >
                        <div className="row g-4 d-flex justify-content-center  p-lg-3" >
                            <div className='text-center mt-5 mb-4' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "14px", lineHeight: "1.2em", color: "#65cef5" }} >OUR PRODUCTS</h6>
                                </div>
                                <h4 className='  laila-bold' style={{ fontSize: "38px", lineHeight: "1.2em", color: "#151515" }} > Delivered fresh to your door <br /> by our team </h4>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='p-4'>
                                        <img className='w-100 border' style={{ borderRadius: "10px" }} src={require("../images/200ml.png")} alt='200mlwaterbottle' title='200mlwaterbottle' />
                                    </div>
                                    <div className='text-center mb-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Sunrich </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 200 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='p-4'>
                                        <img className='w-100 border' style={{ borderRadius: "10px" }} src={require("../images/500ml.png")} alt='500mlwaterbottle' title='500mlwaterbottle' />
                                    </div>
                                    <div className='text-center mb-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Sunrich </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 500 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='p-4'>
                                        <img className='w-100 border' style={{ borderRadius: "10px" }} src={require("../images/1ltr.png")} alt='20ltrwaterbottle' title='20ltrwaterbottle' />
                                    </div>
                                    <div className='text-center mb-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Bailley </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 1Ltr Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='p-4'>
                                        <img className='w-100 border' style={{ borderRadius: "10px" }} src={require("../images/20ltr.png")} alt='20ltrwaterbottle' title='20ltrwaterbottle' />
                                    </div>
                                    <div className='text-center mb-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Aria </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 20Ltr Bottled Water</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className='laila-semibold h-100' style={{ backgroundColor: "white", boxShadow: "0px 0px 30px 0px #0000001a" }} >
                                    <div className='p-4'>
                                        <img className='w-100 border' style={{ borderRadius: "10px" }} src={require("../images/200mlUpdateNew.png")} alt='20lmlwaterbottle' title='20lmlwaterbottle' />
                                    </div>
                                    <div className='text-center mb-3'>
                                        <h2 className='' style={{ fontSize: "20px", fontWeight: "800", color: "#151515" }} > Aria </h2>
                                        <h2 className='' style={{ fontSize: "16px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} > 200 ml Bottled Water</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Product