import React, { useState } from 'react'
import CountUp from 'react-countup';
import ScrollTrigger from "react-scroll-trigger"


const Services = () => {

    const [counterOn, setCounterOn] = useState(false);

    return (

        <>



            <section id='services'>
                <section>
                    <div className="container-lg mt-5 p-lg-5 pb-5 ">
                        <div className="row g-4 d-flex p-2 laila-semibold">
                            <div className='text-center  MainTag' >
                                <div className=''>
                                    <h1 className='laila-bold'  >Packaged Drinking Water Supplier in Pune</h1>
                                </div>
                            </div>
                            <div className=' mt-5 mb-5 d-none d-sm-block' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "14px", lineHeight: "1.2em", color: "#65cef5" }} >SERVICES</h6>
                                </div>
                                <h4 className='WeProvide  laila-bold'  > We pride ourselves on our <br /> standard service. </h4>
                            </div>

                            <div className='text-center d-sm-none d-block' >
                                <div className='' style={{ paddingBottom: "16px" }} >
                                    <h6 className='laila-bold' style={{ fontSize: "14px", lineHeight: "1.2em", color: "#65cef5" }} >SERVICES</h6>
                                </div>
                                <h4 className='WeProvide  laila-bold'  > We pride ourselves on our  standard service. </h4>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='d-flex  '>
                                    <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                    <div className='ms-5'>
                                        <h2 className='' style={{ fontSize: "18px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >All Event</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='d-flex  '>
                                    <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                    <div className='ms-5'>
                                        <h2 className='' style={{ fontSize: "18px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >IT sector</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='d-flex  '>
                                    <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                    <div className='ms-5'>
                                        <h2 className='' style={{ fontSize: "18px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Banking sector</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='d-flex '>
                                    <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                    <div className='ms-5'>
                                        <h2 className='' style={{ fontSize: "18px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Hospitality and catering</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='d-flex  '>
                                    <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                    <div className='ms-5'>
                                        <h2 className='' style={{ fontSize: "18px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Healthcare and pharmaceuticals</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='d-flex  '>
                                    <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                    <div className='ms-5'>
                                        <h2 className='' style={{ fontSize: "18px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Educational sector</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='d-flex  '>
                                    <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                    <div className='ms-5'>
                                        <h2 className='' style={{ fontSize: "18px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Manufacturing and industrial</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='d-flex  '>
                                    <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                    <div className='ms-5'>
                                        <h2 className='' style={{ fontSize: "18px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >Commercial and residential properties</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='d-flex  '>
                                    <i class="fa-solid fa-droplet" style={{ fontSize: "50px", color: "skyblue" }} ></i>
                                    <div className='ms-5'>
                                        <h2 className='' style={{ fontSize: "18px", lineHeight: "34px", fontWeight: "600", marginBottom: "8px", color: "#151515" }} >And Many More...</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className='container-fluid  mb-2' style={{ backgroundColor: "#004DA1" }} >
                        <div className="container p-5 " >
                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                <div className="row g-3 d-flex justify-content-center align-items-center  text-center">
                                    <div className="col-lg-6 col-md-6  d-flex justify-content-center align-items-center">
                                        <div>
                                            <div className='laila-semibold' style={{ fontSize: "48px", color: "#fff" }}> {counterOn && <CountUp start={0} end={7} duration={2} delay={0}> </CountUp>} K + </div>
                                            <p className='laila-semibold' style={{ fontSize: "24px", lineHeight: "30px", textTransform: "capitalize", color: "#fff" }}>Daily Water <br /> Delivered</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                                        <div>
                                            <div className='laila-semibold' style={{ fontSize: "48px", color: "#fff" }}> {counterOn && <CountUp start={0} end={8} duration={2} delay={0}> </CountUp>} + </div>
                                            <p className='laila-semibold' style={{ fontSize: "24px", lineHeight: "30px", textTransform: "capitalize", color: "#fff" }}>Years Of <br /> Experienced</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6  d-flex justify-content-center align-items-center">
                                        <div >
                                            <div className='laila-semibold' style={{ fontSize: "48px", color: "#fff" }}> {counterOn && <CountUp start={0} end={35} duration={2} delay={0}> </CountUp>}</div>
                                            <p className='laila-semibold' style={{ fontSize: "24px", lineHeight: "30px", textTransform: "capitalize", color: "#fff" }}>Professional Team <br /> Members </p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                                        <div>
                                            <div className='laila-semibold' style={{ fontSize: "48px", color: "#fff" }}> {counterOn && <CountUp start={0} end={17} duration={2} delay={0}> </CountUp>}</div>
                                            <p className='laila-semibold' style={{ fontSize: "24px", lineHeight: "30px", textTransform: "capitalize", color: "#fff" }}>Currently Serving <br /> Areas In Pune City</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                                        <div>
                                            <div className='laila-semibold' style={{ fontSize: "48px", color: "#fff" }}> {counterOn && <CountUp start={0} end={760} duration={2} delay={0}> </CountUp>} + </div>
                                            <p className='laila-semibold' style={{ fontSize: "24px", lineHeight: "30px", textTransform: "capitalize", color: "#fff" }}>Customers <br /> Happy With Us</p>
                                        </div>
                                    </div>
                                </div>
                            </ScrollTrigger>
                        </div>
                    </div>
                </section>
            </section>


   



        </>

    )
}

export default Services