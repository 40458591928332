import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Header = () => {
    return (

        <>
            <HelmetProvider>
                <Helmet>
                    <meta charset="utf-8" />
                    <link rel="canonical" href="http://amitwater.com/" />
                    <meta name="keywords" content="Ensuring Access to Clean and Safe Drinking Water for Homes and Businesses" />
                    <title> Drinking Water Supplier in Kothrud | Pune, Maharashtra </title>
                </Helmet>
            </HelmetProvider>

            <section id='main'>
                <div className='container-fluid d-none d-sm-block BGMainImg ' >
                    <div className=" laila-light">
                        <div className="row d-flex justify-content-center align-itmes-center " >
                            <div className="">
                                <div className='VideoMainTag p-0'  >
                                    <video autoPlay loop muted className='VideoMainHeight' style={{ objectFit: "fill" }}  >
                                        <source src={require("../images/demo.mp4")} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid d-sm-none d-block p-0' >
                    <video autoPlay loop muted className='w-100 ' style={{ objectFit:"inherit",height:"270px" }}  >
                        <source src={require("../images/demo.mp4")} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </section>

        </>

    )
}

export default Header