import React, { useState } from 'react'
import { Link } from "react-scroll";
import "./Comman.css"


const Navbar = () => {


    return (

        <>

            <section>
                <div className="container-fluid mainTopMargin  d-none d-sm-block " style={{ backgroundColor: "#004DA1" }} >
                    <div className="container-lg laila-bold p-3 pb-1" >
                        <div className="row ">
                            <ul class=" d-flex justify-content-start align-items-center" style={{ listStyle: "none" }} >
                                <li className="PaddingLeft" style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "500", color: "#fff" }} >
                                    Delivery <a className="text-decoration-none NumberHover ms-3" style={{ fontSize: "14px", cursor: "pointer" }} href='tel:9890050512' > <i class="fa-solid fa-phone me-2 " style={{ color: "#fff", fontSize: "15px" }} > </i> +91  9890050512 </a>  &nbsp; |
                                </li>

                                <li className="PaddingLeft" style={{ fontSize: "15px", lineHeight: "24px", fontWeight: "500", color: "#fff" }} >
                                    <i class="fa-solid fa-clock me-1" style={{ color: "#fff", fontSize: "15px" }} ></i> Mon - Sat : 08.00 to 06.00  &nbsp; |
                                </li>

                                <li className="PaddingLeft ms-lg-5" style={{ fontSize: "15px", lineHeight: "24px", fontWeight: "500", color: "#fff", letterSpacing: "1px" }} >
                                    <i class="fa-solid fa-envelope me-1 " style={{ color: "#fff", fontSize: "15px" }} > </i> Enquire? <a className="text-decoration-none EmailHover ms-2" href='mailto:amitdhepe2921@gmail.com' >  Send Your Mail </a>  &nbsp;
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-sm-none d-block " style={{ backgroundColor: "#004DA1" }} >
                    <div className="row  pt-3 pb-3  d-flex justify-content-between align-items-center text-white laila-semibold">
                        <div className="col-5">
                            <div>
                                <a className='text-decoration-none' href='tel:9890050512'>
                                    <li className='laila-semibold text-white' style={{ listStyle: "none", fontSize: "12.2px" }} > <i class="fa-solid fa-phone"></i>  +91 9890050512  </li>
                                </a>
                            </div>
                        </div>

                        <div className="col-7">
                            <div>
                                <a className='text-decoration-none' href='mailto:amitdhepe2921@gmail.com' >
                                    <li className='laila-semibold text-white' style={{ listStyle: "none", fontSize: "12.3px" }} > <i class="fa-regular fa-envelope"></i> &nbsp; amitdhepe2921@gmail.com</li>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <nav class="navbar navbar-expand-lg navbar-light p-0  m-0 mainMargin d-none d-sm-block" style={{ backgroundImage: `url(${require("../images/HomeBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top,top' }}>
                <div class="container-fluid  MainWidth laila-semibold Padding-Main" style={{ backgroundColor: "white", borderRadius: "0px 0px 15px 15px" }}>
                    <Link className='nav-link ' style={{ cursor: "pointer" }} to='main'>
                        <img className='' src={require("../images/UpdateLogo.png")} alt="Logo" title='Logo' />
                    </Link>
                    <a class="navbar-brand" href="#"></a>
                    <button class="navbar-toggler" style={{ borderRadius: "0px 0px 0px 0px", backgroundColor: "#004da1", color: "#fff" }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fa-solid fa-bars  p-2"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav d-flex justify-content-start align-items-lg-center w-100 ">
                            <li class="nav-item  PaddinggLeftt">
                                <Link className='nav-link ' style={{ cursor: "pointer", color: "#65cef5" }} to='main'>
                                    HOME
                                </Link>
                            </li>

                            <li class="nav-item PaddinggLeftt">
                                <Link className='nav-link ' to='services'>
                                    SERVICES
                                </Link>
                            </li>

                            <li class="nav-item PaddinggLeftt">
                                <Link className='nav-link ' to='about'>
                                    ABOUT
                                </Link>
                            </li>

                            <li class="nav-item PaddinggLeftt">
                                <Link className='nav-link ' to='product'>
                                    PRODUCTS
                                </Link>
                            </li>

                            <li class="nav-item PaddinggLeftt">
                                <Link className='nav-link ' to='contact'>
                                    CONTACT
                                </Link>
                            </li>

                            <li class="nav-item PaddinggLeftt">
                                <Link className='nav-link ' to='client'>
                                    CLIENT
                                </Link>
                            </li>

                            <li class="nav-item PaddinggLefttIcon">
                                <a href="https://wa.me/9890050512">
                                    <div className=''>
                                        <img className='' style={{ height: "45px" }} src={require("../images/wp.jpg")} alt="whatsapp" title='whatsapp' />
                                    </div>
                                </a>
                            </li>

                            <li class="nav-item PaddinggLeftIcon">
                                <a href="">
                                    <div className=''>
                                        <img className='' style={{ height: "38px" }} src={require("../images/fb1.png")} alt="facebook" title='fecebook' />
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <nav class="navbar navbar-expand-lg navbar-light  mainMargin d-sm-none d-block" >
                <div class="container p-3 laila-semibold" style={{ backgroundColor: "white", borderRadius: "0px 0px 15px 15px" }}>
                    <Link className='nav-link ' to='main'>
                        <img className='w-100' src={require("../images/UpdateLogo.png")} alt="Logo" title='logo' />
                    </Link>
                    <a class="navbar-brand" href="#"></a>
                    <button class="navbar-toggler" style={{ borderRadius: "0px 0px 0px 0px", backgroundColor: "#004da1", color: "#fff" }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fa-solid fa-bars  p-2"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav d-flex justify-content-start  w-100 mt-3 pb-2">
                            <li class="nav-item ps-lg-4">
                                <Link className='nav-link ' style={{ cursor: "pointer", color: "#65cef5" }} to='main'>
                                    HOME
                                </Link>
                            </li>
                            <li class="nav-item ps-lg-4">
                                <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='services'>
                                    SERVICES
                                </Link>
                            </li>
                            <li class="nav-item ps-lg-4">
                                <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='blog'>
                                    BLOG
                                </Link>
                            </li>
                            <li class="nav-item ps-lg-4">
                                <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='about'>
                                    ABOUT
                                </Link>
                            </li>
                            <li class="nav-item ps-lg-4">
                                <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='product'>
                                    PRODUCTS
                                </Link>
                            </li>
                            <li class="nav-item ps-lg-4">
                                <Link className='nav-link ' style={{ cursor: "pointer", color: "#151515", fontSize: "15px" }} to='contact'>
                                    CONTACT
                                </Link>
                            </li>
                            <li class="nav-item">
                                <div className='d-flex justify-content-start align-items-center'>
                                    <a href="https://wa.me/9890050512">
                                        <div className=''>
                                            <img className='' style={{ height: "45px" }} src={require("../images/wp.jpg")} alt="whatsapp" title='whatsapp' />
                                        </div>
                                    </a>
                                    <a href="">
                                        <div className=''>
                                            <img className='' style={{ height: "38px" }} src={require("../images/fb1.png")} alt="facebook" title='facebook' />
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>

    )
}

export default Navbar